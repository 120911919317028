<template lang="pug" functional>
  svg(
    :class="[data.class, data.staticClass]"
    width="11"
    height="13"
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg")
    path(
      d="M4.78901 1.01894C4.76313 1.07388 4.73269 1.12669 4.69801 1.17685C4.56127 1.36865 4.39204 1.53651 4.19751 1.6733C3.68141 2.04975 2.68951 2.49441 0.850012 2.49441C0.677622 2.49441 0.512291 2.56096 0.390392 2.67941C0.268494 2.79786 0.200012 2.95852 0.200012 3.12604V5.65254C0.200012 6.95874 0.365113 8.3521 1.09701 9.59766C1.84191 10.8622 3.12241 11.8968 5.19461 12.5676C5.32795 12.6107 5.47208 12.6107 5.60541 12.5676C7.67761 11.898 8.95811 10.8622 9.70171 9.59893C10.4349 8.3521 10.6 6.95874 10.6 5.65254V3.12604C10.6 2.95852 10.5315 2.79786 10.4096 2.67941C10.2877 2.56096 10.1224 2.49441 9.95001 2.49441C8.11181 2.49441 7.11861 2.04975 6.60251 1.6733C6.40845 1.5364 6.23966 1.36854 6.10331 1.17685C6.06869 1.12709 6.03826 1.07471 6.01231 1.0202C5.96743 0.897362 5.88452 0.791025 5.77498 0.715779C5.66544 0.640534 5.53462 0.600066 5.40047 0.599928C5.26632 0.599789 5.13542 0.639987 5.02571 0.715006C4.916 0.790025 4.83417 0.896191 4.78901 1.01894ZM6.01491 1.02778L6.01621 1.03031L6.01231 1.0202L6.01361 1.02399L6.01491 1.02778ZM4.78641 1.02399L4.78901 1.01894L4.78381 1.03157V1.02778L4.78641 1.02399Z"
      fill="currentColor")

</template>

<script>
export default {
  name: 'IconBadge'
}
</script>

<style lang="scss">
</style>
